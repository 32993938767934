// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vins-page-footer { display: flex; align-items: center; justify-content: space-between; padding: 10px; max-width: 1132px; margin: 0 auto; }
.vins-logo-footer { width: 100%; max-width: 100px; }
.vins-footer-container {border-top: 1px solid #dadce0;}
.vins-contact-btn {border-radius: 4px; padding: 11px 15px; background-color: transparent; cursor: pointer; font-size: 18px; color: #007BFF; }
.vins-contact-btn:hover { background-color: #E0EFFE; }`, "",{"version":3,"sources":["webpack://./src/assets/css/footer.css"],"names":[],"mappings":"AAAA,oBAAoB,aAAa,EAAE,mBAAmB,EAAE,8BAA8B,EAAE,aAAa,EAAE,iBAAiB,EAAE,cAAc,EAAE;AAC1I,oBAAoB,WAAW,EAAE,gBAAgB,EAAE;AACnD,wBAAwB,6BAA6B,CAAC;AACtD,mBAAmB,kBAAkB,EAAE,kBAAkB,EAAE,6BAA6B,EAAE,eAAe,EAAE,eAAe,EAAE,cAAc,EAAE;AAC5I,0BAA0B,yBAAyB,EAAE","sourcesContent":[".vins-page-footer { display: flex; align-items: center; justify-content: space-between; padding: 10px; max-width: 1132px; margin: 0 auto; }\r\n.vins-logo-footer { width: 100%; max-width: 100px; }\r\n.vins-footer-container {border-top: 1px solid #dadce0;}\r\n.vins-contact-btn {border-radius: 4px; padding: 11px 15px; background-color: transparent; cursor: pointer; font-size: 18px; color: #007BFF; }\r\n.vins-contact-btn:hover { background-color: #E0EFFE; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
