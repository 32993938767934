import * as XLSX from 'xlsx';
import XLSXStyle from 'xlsx-js-style';
import { BreakdownTitle, Excel_header, PageSpeedDescriptionExcelHeader, PageSpeedImprovemnetNotNeed, improvementListDetailsHeader } from '../service';
import { breakdowntable } from './breakdowntable';
import { applyBackgroundColors, applyStylesToSheet, applyStyleToHeaderOrFooter, applyStyleToRowstaticrow } from './excelstyle';
export const exportToExcel = (jsonData, PageSpeedImprovementData, EtaData, improvementListData, improvementItemBreakdown, pageWiseImprovementItemBreakdown, fileName = 'PageSpeedData.xlsx') => {
    const createMergeRange = (startRow, endRow, startColumn, endColumn) => ({
        s: { r: startRow, c: startColumn },
        e: { r: endRow, c: endColumn }
    });
    // Create improvements list sheet
    const PageImprovementListSheet = XLSX.utils.aoa_to_sheet([[]]);
    // improvements list table
    const pageImprovementListDetailsHeader = [
        [improvementListDetailsHeader.serialNo, improvementListDetailsHeader.pageName, improvementListDetailsHeader.sectionName, improvementListDetailsHeader.issueDetails, improvementListDetailsHeader.empty, improvementListDetailsHeader.empty, improvementListDetailsHeader.empty, improvementListDetailsHeader.empty, improvementListDetailsHeader.empty, improvementListDetailsHeader.empty, improvementListDetailsHeader.improvementType, improvementListDetailsHeader.issueType, improvementListDetailsHeader.issueImpact, improvementListDetailsHeader.comment, improvementListDetailsHeader.empty]
    ]
    PageImprovementListSheet['!cols'] = [
        { wch: 3 }, { wch: 5 }, { wch: 18 }, { wch: 18 }, { wch: 12 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 12 }, { wch: 12 }, { wch: 10 }, { wch: 15 }, { wch: 12 }, { wch: 12 }, { wch: 10 }, { wch: 25 }
    ];
    XLSX.utils.sheet_add_aoa(PageImprovementListSheet, pageImprovementListDetailsHeader, { origin: 'B2' });
    const improvementListEnd = XLSX.utils.decode_range(PageImprovementListSheet['!ref']).e.r;
    applyStyleToHeaderOrFooter(PageImprovementListSheet, improvementListEnd, improvementListEnd, 1, 15);
    XLSX.utils.sheet_add_json(PageImprovementListSheet, improvementListData, { origin: 'B3', skipHeader: true });
    const LastRow = XLSX.utils.decode_range(PageImprovementListSheet['!ref']).e.r;
    const mergeColumnsRowStart = improvementListEnd + 1;
    const mergeColumnsRowEnd = LastRow + 2;
    const issueDetailsmerges = [];
    const commentmerges = [];
    for (let row = mergeColumnsRowStart; row <= mergeColumnsRowEnd; row++) {
        issueDetailsmerges.push(createMergeRange(row - 1, row - 1, 4, 10));
        commentmerges.push(createMergeRange(row - 1, row - 1, 14, 15));
    }
    const improvementListAlignments = [
        {
            col: 1,
            border: {
                top: { style: "thin", color: "black" },
                bottom: { style: "thin", color: "black" },
                left: { style: "thick", color: "black" },
                right: { style: "thin", color: "black" }
            }
        },
        {
            col: 2,
            align: { horizontal: "left", vertical: "bottom" }
        },
        {
            col: 3,
            align: { horizontal: "left", vertical: "bottom" }
        },
        {
            col: 4,
            align: { horizontal: "left", vertical: "bottom" }
        },
        {
            col: 14,
            align: { horizontal: "left", vertical: "bottom" }
        },
        {
            col: 15,
            border: {
                top: { style: "thin", color: "black" },
                bottom: { style: "thin", color: "black" },
                left: { style: "thin", color: "black" },
                right: { style: "thick", color: "black" }
            },
            align: { horizontal: "left", vertical: "bottom" }
        }
    ];
    const improvementListRowAlignments = [
        {
            row: XLSX.utils.decode_range(PageImprovementListSheet['!ref']).e.r + 1,
            startCol: 1,
            endCol: 15,
            cellStyle: {
                border: {
                    bottom: { style: "thick", color: "black" }
                }
            }
        }
    ];
    applyStylesToSheet(PageImprovementListSheet, mergeColumnsRowStart,mergeColumnsRowEnd-1, 1, 15,improvementListAlignments,improvementListRowAlignments);
    // improvements list page wise breakdown hours table
    const improvementListTableRange = XLSX.utils.decode_range(PageImprovementListSheet['!ref']);
    const endrow = improvementListTableRange.e.r;
    breakdowntable(PageImprovementListSheet, pageWiseImprovementItemBreakdown, endrow + 4, "pageWiseImprovementItemBreakdown");
    const pageWiseBreakdownTableRange = XLSX.utils.decode_range(PageImprovementListSheet['!ref']);
    const lastCell = pageWiseBreakdownTableRange.e.r;
    const remarksMergeColumnsRowStart = endrow + 4;
    const remarksMergeColumnsRowEnd = lastCell + 2;
    const pageWiseRemarkMerge = [];
    for (let row = remarksMergeColumnsRowStart; row <= remarksMergeColumnsRowEnd; row++) {
        pageWiseRemarkMerge.push(createMergeRange(row - 1, row - 1, 12, 15));
    }
    const title = [[BreakdownTitle.title]]
    XLSX.utils.sheet_add_aoa(PageImprovementListSheet, title, { origin: `B${lastCell + 3 + 1}` });
    applyStyleToRowstaticrow(PageImprovementListSheet, lastCell + 3);
    // improvements list item breakdown hours table
    const afterBreakdownRange = XLSX.utils.decode_range(PageImprovementListSheet['!ref']);
    const breakdownLastCell = afterBreakdownRange.e.r;
    breakdowntable(PageImprovementListSheet, improvementItemBreakdown, breakdownLastCell + 3, "improvementItemBreakdown");
    const improvementItemBreakdownLastRow = XLSX.utils.decode_range(PageImprovementListSheet['!ref']).e.r;
    const improvementItemBreakdownMergeColumnsRowStart = breakdownLastCell;
    const improvementItemBreakdownMergeColumnsRowEnd = improvementItemBreakdownLastRow + 1;
    const improvementItemBreakdownmerges = [];
    for (let row = improvementItemBreakdownMergeColumnsRowStart; row <= improvementItemBreakdownMergeColumnsRowEnd; row++) {
        improvementItemBreakdownmerges.push(createMergeRange(row - 1, row - 1, 14, 15));
    }
    PageImprovementListSheet['!merges'] = [
        ...issueDetailsmerges,
        ...commentmerges,
        ...pageWiseRemarkMerge,
        { s: { r: lastCell + 3, c: 1 }, e: { r: lastCell + 3, c: 15 } },
        ...improvementItemBreakdownmerges,
    ];
    //Page score sheet
    const headers = [
        [Excel_header.s_no, Excel_header.page_name, Excel_header.page_url, Excel_header.desktop, Excel_header.empty_space, Excel_header.empty_space, Excel_header.empty_space, Excel_header.mobile, Excel_header.empty_space, Excel_header.empty_space, Excel_header.empty_space],
        [Excel_header.empty_space, Excel_header.empty_space, Excel_header.empty_space, Excel_header.desktop_score, Excel_header.desktop_fcp, Excel_header.desktop_lcp, Excel_header.desktop_cls, Excel_header.mobile_score, Excel_header.mobile_fcp, Excel_header.mobile_lcp, Excel_header.mobile_cls]
    ];
    const workbook = XLSX.utils.book_new();
    const PageScoreSheet = XLSX.utils.aoa_to_sheet([[]]);
    PageScoreSheet['!cols'] = [
        { wch: 5 },
        { wch: 7 },
        { wch: 30 },
        { wch: 20 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 }
    ];
    PageScoreSheet['!merges'] = [
        { s: { r: 1, c: 1 }, e: { r: 2, c: 1 } },
        { s: { r: 1, c: 2 }, e: { r: 2, c: 2 } },
        { s: { r: 1, c: 3 }, e: { r: 2, c: 3 } },
        { s: { r: 1, c: 4 }, e: { r: 1, c: 7 } },
        { s: { r: 1, c: 8 }, e: { r: 1, c: 11 } }
    ];
    XLSX.utils.sheet_add_aoa(PageScoreSheet, headers, { origin: 'B2' });
    const HeaderEnd = XLSX.utils.decode_range(PageScoreSheet['!ref']);
    applyStyleToHeaderOrFooter(PageScoreSheet, 1, 2, HeaderEnd.s.c + 1, HeaderEnd.e.c);
    XLSX.utils.sheet_add_json(PageScoreSheet, jsonData, { origin: 'B4', skipHeader: true });
    const range = XLSX.utils.decode_range(PageScoreSheet['!ref']);
    const RowEnd = range.e.r;
    const TableRowEnd = RowEnd + 2;
    const specificColumnAlignments = [
        {
            col: 1,
            border: {
                top: { style: "thin", color: "black" },
                bottom: { style: "thin", color: "black" },
                left: { style: "thick", color: "black" },
                right: { style: "thin", color: "black" }
            }
        },
        {
            col: 2,
            align: { horizontal: "left", vertical: "bottom" }
        },
        {
            col: 3,
            align: { horizontal: "left", vertical: "bottom", wrapText: false }
        },
        {
            col: 7,
            border: {
                top: { style: "thin", color: "black" },
                bottom: { style: "thin", color: "black" },
                left: { style: "thin", color: "black" },
                right: { style: "thick", color: "black" }
            }
        },
        {
            col: 11,
            border: {
                top: { style: "thin", color: "black" },
                bottom: { style: "thin", color: "black" },
                left: { style: "thin", color: "black" },
                right: { style: "thick", color: "black" }
            }
        }
    ];
   
    const last_row = [
        [Excel_header.empty_space, Excel_header.empty_space, Excel_header.empty_space, Excel_header.empty_space, Excel_header.empty_space, Excel_header.empty_space, Excel_header.empty_space, Excel_header.empty_space, Excel_header.empty_space, Excel_header.empty_space, Excel_header.empty_space]
    ];
    XLSX.utils.sheet_add_json(PageScoreSheet, last_row, { origin: `B${TableRowEnd}`, skipHeader: true });
    const lastRowEnd = range.e.r;
    const lasTableRowEnd = lastRowEnd + 1;
    const specificRowStylesScoreSheet = [
        {
            row: lasTableRowEnd,
            startCol: 1,
            endCol: 11,
            cellStyle: {
                border: {
                    bottom: { style: "thick", color: "black" }
                }
            }
        }
    ];
    applyStylesToSheet(PageScoreSheet, 3, lasTableRowEnd, 1, 11, specificColumnAlignments,specificRowStylesScoreSheet);
    applyStyleToRowstaticrow(PageScoreSheet, TableRowEnd);
    applyBackgroundColors(PageScoreSheet, 4, TableRowEnd);
    // Create Page Speed Improvement Sheet
    // Descripition table creation
    const PageSpeedImprovementSheet = XLSX.utils.aoa_to_sheet([[]]);
    const PageSpeedImprovementHeader = [
        [PageSpeedDescriptionExcelHeader.s_no, PageSpeedDescriptionExcelHeader.pageName, PageSpeedDescriptionExcelHeader.module, PageSpeedDescriptionExcelHeader.imrpovement, PageSpeedDescriptionExcelHeader.empty, PageSpeedDescriptionExcelHeader.empty, PageSpeedDescriptionExcelHeader.description, PageSpeedDescriptionExcelHeader.empty, PageSpeedDescriptionExcelHeader.empty, PageSpeedDescriptionExcelHeader.empty, PageSpeedDescriptionExcelHeader.remarks, PageSpeedDescriptionExcelHeader.empty, PageSpeedDescriptionExcelHeader.empty,]
    ];
    const PagespeedImprovementNotNeeded = [[PageSpeedImprovemnetNotNeed.content]];
    let DescHeadlastRow = 0;
    if (PageSpeedImprovementData.length === 0) {
        XLSX.utils.sheet_add_aoa(PageSpeedImprovementSheet, PagespeedImprovementNotNeeded, { origin: 'B2' });
        applyStyleToRowstaticrow(PageSpeedImprovementSheet, 1);
        PageSpeedImprovementSheet['!merges'] = [
            { s: { r: 1, c: 1 }, e: { r: 1, c: 12 } }];
    } else {
        XLSX.utils.sheet_add_aoa(PageSpeedImprovementSheet, PageSpeedImprovementHeader, { origin: 'B2' });
        const DescriptionHeadRange = XLSX.utils.decode_range(PageSpeedImprovementSheet['!ref']);
        DescHeadlastRow = DescriptionHeadRange.e.r;
        applyStyleToHeaderOrFooter(PageSpeedImprovementSheet, DescHeadlastRow, DescHeadlastRow, 1, 13);
        XLSX.utils.sheet_add_json(PageSpeedImprovementSheet, PageSpeedImprovementData, { origin: 'B3', skipHeader: true });
        const DescContentRange = XLSX.utils.decode_range(PageSpeedImprovementSheet['!ref']);
        const DescContentLastRow = DescContentRange.e.r;
        const mergeColumnsFromRow = DescHeadlastRow + 1;
        const mergeColumnsToRow = DescContentLastRow + 2;
        const Improvemerges = [];
        const Desmerges = [];
        const RemarksMerges = [];
        for (let row = mergeColumnsFromRow; row <= mergeColumnsToRow; row++) {
            Improvemerges.push(createMergeRange(row - 1, row - 1, 4, 6));
            Desmerges.push(createMergeRange(row - 1, row - 1, 7, 10))
            RemarksMerges.push(createMergeRange(row - 1, row - 1, 11, 12));
        }
        const specificAlignmentsforimprovement = [
            {
                col: 1,
                border: {
                    top: { style: "thin", color: "black" },
                    bottom: { style: "thin", color: "black" },
                    left: { style: "thick", color: "black" },
                    right: { style: "thin", color: "black" }
                }
            },
            {
                col: 2,
                align: { horizontal: "left", vertical: "bottom" }
            },
            {
                col: 4,
                align: { horizontal: "left", vertical: "bottom" }
            },
            {
                col: 7,
                align: { horizontal: "left", vertical: "bottom" }
            },
            {
                col: 11,
                align: { horizontal: "left", vertical: "bottom" }
            },
            {
                col: 12,
                align: { horizontal: "left", vertical: "bottom" },
                border: {
                    top: { style: "thin", color: "black" },
                    bottom: { style: "thin", color: "black" },
                    left: { style: "thin", color: "black" },
                    right: { style: "thick", color: "black" }
                }
            }
        ];
        const specificRowStyles = [
            {
                row: DescContentLastRow + 1,
                startCol: 1,
                endCol: 12,
                cellStyle: {
                    border: {
                        bottom: { style: "thick", color: "black" }
                    }
                }
            }
        ];
        applyStylesToSheet(PageSpeedImprovementSheet, mergeColumnsFromRow, mergeColumnsToRow - 1, 1, 12, specificAlignmentsforimprovement, specificRowStyles);
        PageSpeedImprovementSheet['!cols'] = [
            { wch: 5 }, { wch: 10 }, { wch: 30 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 30 }
        ];
        // ETA Calculation table creation
        const messageRange = XLSX.utils.decode_range(PageSpeedImprovementSheet['!ref']);
        const lastRow = messageRange.e.r;
        const newStartRow = lastRow + 4;
        breakdowntable(PageSpeedImprovementSheet, EtaData, newStartRow);
        //Break down hours title 
        const etaTotal = XLSX.utils.decode_range(PageSpeedImprovementSheet['!ref']);
        const etaTotalLastRow = etaTotal.e.r;
        const breakdownTotal = etaTotalLastRow + 3;
        PageSpeedImprovementSheet['!merges'] = [
            { s: { r: 1, c: 4 }, e: { r: 1, c: 6 } },
            { s: { r: 1, c: 7 }, e: { r: 1, c: 10 } },
            { s: { r: 1, c: 11 }, e: { r: 1, c: 13 } },
            { s: { r: breakdownTotal, c: 1 }, e: { r: breakdownTotal, c: 12 } },
            ...Improvemerges,
            ...Desmerges,
            ...RemarksMerges
        ];
        // }
        const Title = [[BreakdownTitle.title]]
        XLSX.utils.sheet_add_aoa(PageSpeedImprovementSheet, Title, { origin: `B${breakdownTotal + 1}` });
        applyStyleToRowstaticrow(PageSpeedImprovementSheet, breakdownTotal);
        //Breakdown table
        const BreakdownRange = XLSX.utils.decode_range(PageSpeedImprovementSheet['!ref']);
        const lastBreakdownRow = BreakdownRange.e.r;
        const breakdowntable2 = lastBreakdownRow + 3;
        breakdowntable(PageSpeedImprovementSheet, EtaData, breakdowntable2);
    }
    XLSX.utils.book_append_sheet(workbook, PageImprovementListSheet, 'Improvement list');
    XLSX.utils.book_append_sheet(workbook, PageScoreSheet, 'Page-score');
    XLSX.utils.book_append_sheet(workbook, PageSpeedImprovementSheet, 'Page-speed-improvements-items');
    XLSXStyle.writeFile(workbook, fileName);
};