import axios from 'axios';
import { titleMapping, titleInputs, title_with_url } from '../service';

const fetchPageSpeedData = async (url, strategy) => {
    const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${encodeURIComponent(url)}&category=performance&strategy=${strategy}`,
        headers: {}
    };
    return axios.request(config);
};

// To format metrics like FCP, LCP, CLS
const formatMetric = (value, divisor, decimalPlaces) => {
    return (Math.round((value / divisor) * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces)).toFixed(decimalPlaces);
};

// To merge task data
const mergeTaskData = (data) => {
    return data.reduce((taskMap, item) => {
        const taskKey = item.task;
        const existingTask = taskMap[taskKey];
        const newUrls = existingTask ? item.urls.filter(url => !existingTask.urls.includes(url)) : item.urls;

        taskMap[taskKey] = existingTask
            ? { ...existingTask, urls: [...existingTask.urls, ...newUrls], issueCount: existingTask.urls.length + newUrls.length }
            : { ...item };

        return taskMap;
    }, {});
};

// Tto extract snippets from data items
const extractSnippets = (dataArray) => {
    return dataArray
        .map(item => item.node?.snippet || item.entity || item.url || null)
        .filter(snippet => snippet !== null);
};

// Count URLs that match a specific domain
const countUrlsByDomain = (items, domain) => {
    const domainHostname = new URL(domain).hostname;

    const matchingUrls = items
        .filter(item => item.url && isValidUrlForDomain(item.url, domainHostname))
        .map(item => item.url);

    return { count: matchingUrls.length, matchingUrls };
};

// Check if a URL is valid and matches the domain
const isValidUrlForDomain = (url, domainHostname) => {
    try {
        const itemHostname = new URL(url).hostname;
        return itemHostname.includes(domainHostname);
    } catch (error) {
        return false;
    }
};

// Get the improvement data by title
const getHeadingsByTitle = (data, titles, url) => {
    return titles.reduce((result, title, index) => {
        const metric = findMetricByTitle(data, title);
        if (metric && metric.details?.items.length) {
            const urls = title_with_url.includes(metric.title)
                ? countUrlsByDomain(metric.details.items, url).matchingUrls
                : extractSnippets(metric.details.items);

            if (urls.length) {
                result.push(mapToImprovementData(index, title, urls));
            }
        }
        return result;
    }, []);
};

// Find the metric in the data by title
const findMetricByTitle = (data, title) => {
    return Object.values(data).find(metric => metric.title === title);
};

// Map the title and URL information to improvement data
const mapToImprovementData = (index, title, urls) => {
    const mappedValue = titleMapping[title];
    return {
        s__No: index + 1,
        page_name: mappedValue['page_name'],
        modules: mappedValue['Modules'],
        improvement_item: mappedValue['improvement_item'],
        task: mappedValue['task'],
        empty: '',
        description: mappedValue['description'],
        remarks: mappedValue['remarks'],
        issueCount: urls.length,
        urls
    };
};
function getMetricValue(metricKey, metrics, lighthouseMetrics, displayKey, formatFunction, divisor, decimalPlaces) {
    if (metrics[metricKey]?.percentile) {
        return formatFunction(metrics[metricKey].percentile, divisor, decimalPlaces) + (divisor === 1000 ? "s" : "");
    } else if (lighthouseMetrics[displayKey]?.displayValue) {
        return lighthouseMetrics[displayKey].displayValue;
    }
    return '-';
}

export const analyzePageSpeed = async (url, strategy) => {
    try {
        const response = await fetchPageSpeedData(url, strategy);
        const metrics = response.data.loadingExperience?.metrics || {};
        const lighthouseMetrics = response.data.lighthouseResult?.audits || {};
        const score = response.data.lighthouseResult.categories.performance?.score * 100 || '-';        
        const fcp = getMetricValue('FIRST_CONTENTFUL_PAINT_MS', metrics, lighthouseMetrics, 'first-contentful-paint', formatMetric, 1000, 1);
        const lcp = getMetricValue('LARGEST_CONTENTFUL_PAINT_MS', metrics, lighthouseMetrics, 'largest-contentful-paint', formatMetric, 1000, 1);
        const cls = getMetricValue('CUMULATIVE_LAYOUT_SHIFT_SCORE', metrics, lighthouseMetrics, 'cumulative-layout-shift', formatMetric, 100, 3);
        const pageSpeedImprovementData = getHeadingsByTitle(lighthouseMetrics, titleInputs, url);
        const mergedData = Object.values(mergeTaskData(pageSpeedImprovementData));

        return {
            score,
            fcp,
            lcp,
            cls,
            messages: mergedData
        };
    } catch (error) {
        console.error(`Error fetching PageSpeed Insights data for ${strategy}:`, error);
        return {
            score: 'Error',
            fcp: 'Error',
            lcp: 'Error',
            cls: 'Error'
        };
    }
};