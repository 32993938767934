import React from 'react';
import FooterLogo from '../assets/images/footer-logo.png';
import '../assets/css/footer.css'

export default function Footer() {
  return (
    <div className='vins-footer-container'>
      <footer className='vins-page-footer'>
        <div className='vins-logo-container-footer'>
          <img className='vins-logo-footer' src={FooterLogo} alt="Footer Logo" />
        </div>
        <div className='vins-page-btns'>
          <a className='vins-contact-btn' href='#'>Contact Us</a>
        </div>
      </footer>
    </div>
  )
}
