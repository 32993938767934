import * as XLSX from 'xlsx';
export const createMergeRange = (startRow, endRow, startColumn, endColumn) => ({
    s: { r: startRow, c: startColumn },
    e: { r: endRow, c: endColumn }
});

export function applyStyleToRowstaticrow(sheet, startRow = 0) {
    const range = XLSX.utils.decode_range(sheet['!ref']);
    const validStartRow = Math.max(startRow, range.s.r);
    for (let rowIndex = validStartRow; rowIndex <= range.e.r; rowIndex++) {
        for (let colIndex = range.s.c; colIndex <= range.e.c; colIndex++) {
            const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });
            sheet[cellAddress] = {
                ...(sheet[cellAddress] || {}), s: {
                    font: { sz: 11, bold: true },
                    alignment: { vertical: "center", horizontal: "center" },
                }
            };
        }
    }
}

export function applyStyleToRange(sheet, startRow, endRow, startCol, endCol) {
    for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
        for (let colIndex = startCol; colIndex <= endCol; colIndex++) {
            const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });
            sheet[cellAddress] = {
                ...(sheet[cellAddress] || {}),
                v: sheet[cellAddress]?.v || '',
                s: {
                    font: { sz: 11, bold: false },
                    alignment: { vertical: "center", horizontal: "center", wrapText: true },
                    border: {
                        top: { style: "thin", color: "black" },
                        bottom: { style: "thin", color: "black" },
                        left: { style: "thin", color: "black" },
                        right: { style: "thin", color: "black" }
                    }
                }
            };
        }
    }
}

export function applyStyleToHeaderOrFooter(sheet, startRow, endRow, startCol, endCol) {
    for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
        for (let colIndex = startCol; colIndex <= endCol; colIndex++) {
            const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });
            sheet[cellAddress] = {
                ...(sheet[cellAddress] || {}),
                v: sheet[cellAddress]?.v || '',
                f: sheet[cellAddress]?.f || undefined,
                s: {
                    font: { sz: 11, bold: true },
                    alignment: { vertical: "bottom", horizontal: "center", wrapText: true},
                    border: {
                        top: { style: "thick", color: "black" },
                        bottom: { style: "thick", color: "black" },
                        left: { style: "thick", color: "black" },
                        right: { style: "thick", color: "black" }
                    }
                }
            };
        }
    }
}

export function applyStyleToBreakdownHeader(sheet, startRow, endRow) {
    const range = XLSX.utils.decode_range(sheet['!ref']);
    const validStartRow = Math.max(startRow, range.s.r);
    const validEndRow = Math.min(endRow ?? range.e.r, range.e.r);

    for (let rowIndex = validStartRow; rowIndex <= validEndRow; rowIndex++) {
        for (let colIndex = range.s.c; colIndex <= range.e.c; colIndex++) {
            const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });
            sheet[cellAddress] = {
                ...(sheet[cellAddress] || {}),
                s: {
                    font: { sz: 11, bold: true },
                    alignment: { vertical: "bottom", horizontal: "center", wrapText: true },
                    border: {
                        top: { style: "thick", color: "black" },
                        bottom: { style: "thick", color: "black" },
                        left: { style: "thick", color: "black" },
                        right: { style: "thick", color: "black" }
                    },
                    fill: {
                        patternType: "solid",
                        fgColor: { rgb: "7DA7D8" }
                    }
                }
            };
        }
    }
}

export function applyTextAlignmentToColumn(sheet, startRow, endRow, startColumn, endColumn = startColumn) {
    for (let row = startRow; row <= endRow; row++) {
        for (let col = startColumn; col <= endColumn; col++) {
            const cellAddress = XLSX.utils.encode_cell({ c: col, r: row });
            sheet[cellAddress] = {
                ...(sheet[cellAddress] || {}),
                s: {
                    font: { sz: 11, bold: false },
                    alignment: { vertical: "top", horizontal: "left", wrapText: true },
                }
            };
        }
    }
}
export function applyStylesToSheet(sheet, startRow, endRow, startCol, endCol, specificColumnAlignments = [], specificRowStyles = []) {
    for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
        for (let colIndex = startCol; colIndex <= endCol; colIndex++) {
            const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });
            const cellStyle = getDefaultCellStyle();

            applyColumnAlignments(cellStyle, colIndex, specificColumnAlignments);
            applyRowStyles(cellStyle, rowIndex, colIndex, specificRowStyles);

            sheet[cellAddress] = {
                ...(sheet[cellAddress] || { v: '', t: 's', f: undefined }),
                s: cellStyle
            };
        }
    }
}

function getDefaultCellStyle() {
    return {
        font: { sz: 11, bold: false },
        alignment: { vertical: "bottom", horizontal: "center", wrapText: true },
        border: {
            top: { style: "thin", color: "black" },
            bottom: { style: "thin", color: "black" },
            left: { style: "thin", color: "black" },
            right: { style: "thin", color: "black" }
        }
    };
}

function applyColumnAlignments(cellStyle, colIndex, specificColumnAlignments) {
    specificColumnAlignments.forEach(style => {
        const { col, align, border } = style;
        if (colIndex === col) {
            if (align) {
                cellStyle.alignment = { ...cellStyle.alignment, ...align };
            }
            if (border) {
                cellStyle.border = { ...cellStyle.border, ...border };
            }
        }
    });
}

function applyRowStyles(cellStyle, rowIndex, colIndex, specificRowStyles) {
    specificRowStyles.forEach(style => {
        const { row, startCol, endCol, cellStyle: rowStyle } = style;
        if (rowIndex === row && colIndex >= startCol && colIndex <= endCol) {
            if (rowStyle?.border?.bottom) {
                cellStyle.border.bottom = { ...cellStyle.border.bottom, ...rowStyle.border.bottom };
            }
        }
    });
}
export function applyBackgroundColors(sheet, startRow, endRow, columns) {
    const colorRules = getColorRules();
    const cellsWithColors = [];

    Object.keys(sheet).forEach((cellAddress) => {
        if (cellAddress[0] === '!') return; // Skip non-cell data

        const { row, colIndex } = getRowAndColIndex(cellAddress);
        if (row === null || colIndex === null) return; // Invalid cell format

        if (isWithinRange(row, startRow, endRow)) {
            const cell = sheet[cellAddress];
            const rules = colorRules[colIndex];
            if (rules) {
                const bgColor = getBackgroundColor(cell.v, rules.ranges);
                if (bgColor !== 'FFFFFF') {
                    applyCellStyle(sheet, cellAddress, bgColor);
                    cellsWithColors.push({ cell: cellAddress, color: bgColor });
                }
            }
        }
    });

    return cellsWithColors;
}

function getColorRules() {
    return {
        '5': createRanges(1.8, 3.0),
        '9': createRanges(1.8, 3.0),
        '6': createRanges(2.5, 4.0),
        '10': createRanges(2.5, 4.0),
        '7': createRanges(0.1, 0.25),
        '11': createRanges(0.1, 0.25)
    };
}

function createRanges(min1, min2) {
    return {
        ranges: [
            { min: -Infinity, max: min1, color: '92D050' },
            { min: min1, max: min2, color: 'FFA500' },
            { min: min2, max: Infinity, color: 'FF0000' }
        ]
    };
}

function getRowAndColIndex(cellAddress) {
    const rowMatch = cellAddress.match(/\d+/);
    const colMatch = cellAddress.match(/[A-Z]+/);
    const row = rowMatch ? parseInt(rowMatch[0], 10) : null;
    const colIndex = colMatch ? colMatch[0].charCodeAt(0) - 'A'.charCodeAt(0) : null;
    return { row, colIndex };
}

function isWithinRange(row, startRow, endRow) {
    return row >= startRow && row <= endRow;
}

function getBackgroundColor(value, ranges) {
    const numericValue = parseFloat(value);
    for (const range of ranges) {
        if (numericValue >= range.min && numericValue <= range.max) {
            return range.color;
        }
    }
    return 'FFFFFF';
}

function applyCellStyle(sheet, cellAddress, bgColor) {
    if (!sheet[cellAddress].s) {
        sheet[cellAddress].s = {};
    }
    sheet[cellAddress].s.fill = {
        patternType: 'solid',
        fgColor: { rgb: bgColor }
    };
}
