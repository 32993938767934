// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a { text-decoration: none; }
.vins-page-header { padding: 10px; border-bottom: 1px solid #dadce0; }
.vins-title, .vins-page-btns { display: flex; align-items: center;gap: 20px; }
.vins-logo { width: 100%; max-width: 55px; }
.vins-title { color: #111111; font-size: 18px;font-family: "Inter-medium", sans-serif; }
.vins-btn { padding: 10px; border: 1px solid #000; border-radius: 5px; font-size: 14px; }
.vins-logo-container { max-width: 1132px; margin: 0 auto; }`, "",{"version":3,"sources":["webpack://./src/assets/css/header.css"],"names":[],"mappings":"AAAA,IAAI,qBAAqB,EAAE;AAC3B,oBAAoB,aAAa,EAAE,gCAAgC,EAAE;AACrE,+BAA+B,aAAa,EAAE,mBAAmB,CAAC,SAAS,EAAE;AAC7E,aAAa,WAAW,EAAE,eAAe,EAAE;AAC3C,cAAc,cAAc,EAAE,eAAe,CAAC,uCAAuC,EAAE;AACvF,YAAY,aAAa,EAAE,sBAAsB,EAAE,kBAAkB,EAAE,eAAe,EAAE;AACxF,uBAAuB,iBAAiB,EAAE,cAAc,EAAE","sourcesContent":["a { text-decoration: none; }\r\n.vins-page-header { padding: 10px; border-bottom: 1px solid #dadce0; }\r\n.vins-title, .vins-page-btns { display: flex; align-items: center;gap: 20px; }\r\n.vins-logo { width: 100%; max-width: 55px; }\r\n.vins-title { color: #111111; font-size: 18px;font-family: \"Inter-medium\", sans-serif; }\r\n.vins-btn { padding: 10px; border: 1px solid #000; border-radius: 5px; font-size: 14px; }\r\n.vins-logo-container { max-width: 1132px; margin: 0 auto; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
