// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --contact-btn-hover-bgcolor: #E0EFFE;
    --btn-txt-color: #007BFF;
    --footer-border-color: #dadce0;
}
.vins-page-footer { display: flex; align-items: center; justify-content: space-between; padding: 10px 20px;}
.vins-logo-footer { width: 100%; max-width: 100px; }
.vins-footer-container {border-top: 1px solid var(--footer-border-color);}
.vins-contact-btn {border-radius: 4px; padding: 11px 15px; background-color: transparent; cursor: pointer; font-size: 18px; line-height: 21px; color: var(--btn-txt-color); }
.vins-contact-btn:hover { background-color: var(--contact-btn-hover-bgcolor); }
@media screen and (max-width: 991px) {
    .vins-contact-btn { font-size: 17px; line-height: 20px; }
}
@media screen and (max-width: 768px) {
    .vins-contact-btn { font-size: 16px; line-height: 19px; }
}`, "",{"version":3,"sources":["webpack://./src/assets/css/footer.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,wBAAwB;IACxB,8BAA8B;AAClC;AACA,oBAAoB,aAAa,EAAE,mBAAmB,EAAE,8BAA8B,EAAE,kBAAkB,CAAC;AAC3G,oBAAoB,WAAW,EAAE,gBAAgB,EAAE;AACnD,wBAAwB,gDAAgD,CAAC;AACzE,mBAAmB,kBAAkB,EAAE,kBAAkB,EAAE,6BAA6B,EAAE,eAAe,EAAE,eAAe,EAAE,iBAAiB,EAAE,2BAA2B,EAAE;AAC5K,0BAA0B,kDAAkD,EAAE;AAC9E;IACI,oBAAoB,eAAe,EAAE,iBAAiB,EAAE;AAC5D;AACA;IACI,oBAAoB,eAAe,EAAE,iBAAiB,EAAE;AAC5D","sourcesContent":[":root {\r\n    --contact-btn-hover-bgcolor: #E0EFFE;\r\n    --btn-txt-color: #007BFF;\r\n    --footer-border-color: #dadce0;\r\n}\r\n.vins-page-footer { display: flex; align-items: center; justify-content: space-between; padding: 10px 20px;}\r\n.vins-logo-footer { width: 100%; max-width: 100px; }\r\n.vins-footer-container {border-top: 1px solid var(--footer-border-color);}\r\n.vins-contact-btn {border-radius: 4px; padding: 11px 15px; background-color: transparent; cursor: pointer; font-size: 18px; line-height: 21px; color: var(--btn-txt-color); }\r\n.vins-contact-btn:hover { background-color: var(--contact-btn-hover-bgcolor); }\r\n@media screen and (max-width: 991px) {\r\n    .vins-contact-btn { font-size: 17px; line-height: 20px; }\r\n}\r\n@media screen and (max-width: 768px) {\r\n    .vins-contact-btn { font-size: 16px; line-height: 19px; }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
