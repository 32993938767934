import './App.css';
import './assets/css/dashboard.css';
import Dashboard from './components/dashboard';
import Footer from './components/footer';
import Formfields from './components/formfields';
import Header from './components/header';
import React, { useEffect, useState } from 'react';

function App() {
  const [contentHeight, setContentHeight] = useState('auto');
  const [loading, setLoading] = useState(false); // Add loading state

  useEffect(() => {
    const updateContentHeight = () => {
      const header = document.querySelector('.vins-page-header');
      const footer = document.querySelector('.vins-page-footer');
      if (header && footer) {
        const headerHeight = header.offsetHeight;
        const footerHeight = footer.offsetHeight;
        const viewportHeight = window.innerHeight;
        setContentHeight(viewportHeight - headerHeight - footerHeight);
      }
    };
    updateContentHeight(); 
    window.addEventListener('resize', updateContentHeight); 
    return () => window.removeEventListener('resize', updateContentHeight); 
  }, []);

  return (
    <div className={`vins-app-page-content ${loading ? 'blurred' : ''}`}>
      <Header />
      <main style={{ minHeight: `${contentHeight}px` }}>
        <Dashboard />
        <Formfields setLoading={setLoading} /> {/* Pass setLoading to Formfields */}
      </main>
      <Footer />
    </div>
  );
}

export default App;
