import * as XLSX from 'xlsx';
import { PageSpeedDescriptionEtaHeader } from "../service";
import { applyStylesToSheet, applyStyleToBreakdownHeader, applyStyleToHeaderOrFooter} from './excelstyle';

export function breakdowntable(sheetName, EtaData, startRowNumber, tableName = "") {
    let endColumn = 12;
    const PageSpeedImrpovementEtaHeader = [[
        PageSpeedDescriptionEtaHeader.s_no,
        PageSpeedDescriptionEtaHeader.taskName,
        PageSpeedDescriptionEtaHeader.databaseAdministration,
        PageSpeedDescriptionEtaHeader.projectManagement,
        PageSpeedDescriptionEtaHeader.visualDesign,
        PageSpeedDescriptionEtaHeader.qa,
        PageSpeedDescriptionEtaHeader.api,
        PageSpeedDescriptionEtaHeader.research,
        PageSpeedDescriptionEtaHeader.programming,
        PageSpeedDescriptionEtaHeader.systemAdministration,
        PageSpeedDescriptionEtaHeader.totalEfforts,
        PageSpeedDescriptionEtaHeader.remarks]
    ];
    if (tableName === "improvementItemBreakdown") {
        const addtionalHeaders = [
            PageSpeedDescriptionEtaHeader.issueType,
            PageSpeedDescriptionEtaHeader.issue_improvement
        ];
        PageSpeedImrpovementEtaHeader[0].splice(10, 0, ...addtionalHeaders);
    } 
    const newOrigin = `B${startRowNumber + 1}`;
    const HeaderendRowNumber = startRowNumber + 1;
    XLSX.utils.sheet_add_aoa(sheetName, PageSpeedImrpovementEtaHeader, { origin: newOrigin });
    applyStyleToBreakdownHeader(sheetName, startRowNumber, HeaderendRowNumber);
    const dataOrigin = `B${startRowNumber + 2}`;
    if (tableName === "improvementItemBreakdown") {
        EtaData.forEach((item, index) => {
            const rowNumber = startRowNumber + 2 + index;
            sheetName[`E${rowNumber}`] = { f: `SUM(D${rowNumber},F${rowNumber}:K${rowNumber})*0.1` };
            sheetName[`G${rowNumber}`] = { f: `SUM(D${rowNumber},F${rowNumber},H${rowNumber}:K${rowNumber})*0.2` };
            sheetName[`J${rowNumber}`] = { v: item.programming_time };
            sheetName[`N${rowNumber}`] = { f: `SUM(D${rowNumber}:K${rowNumber})` };
        });
    } else {
        EtaData.forEach((item, index) => {
            const rowNumber = startRowNumber + 2 + index;
            sheetName[`E${rowNumber}`] = { f: `SUM(D${rowNumber},F${rowNumber}:K${rowNumber})*0.1` };
            sheetName[`G${rowNumber}`] = { f: `SUM(D${rowNumber},F${rowNumber},H${rowNumber}:K${rowNumber})*0.2` };
            sheetName[`J${rowNumber}`] = { v: item.programming_time };
            sheetName[`L${rowNumber}`] = { f: `SUM(D${rowNumber}:K${rowNumber})` };
        });
    }
    XLSX.utils.sheet_add_json(sheetName, EtaData, { origin: dataOrigin, skipHeader: true });
    const contnetEndRange = XLSX.utils.decode_range(sheetName['!ref']);
    const contentEndRow = contnetEndRange.e.r;
    const contnetTableEndRow = contentEndRow + 1;
    const specificAlignments = [
        {
            col: 1, 
            border: {
                top: { style: "thin", color: "black" },
                bottom: { style: "thin", color: "black" },
                left: { style: "thick", color: "black" },
                right: { style: "thin", color: "black" }
            }
        },
        {
            col: 2, 
            align: { horizontal: "left" ,vertical: "bottom"}
        },
        {
            col: 12, 
            align: { horizontal: "left" ,vertical: "bottom" },
            border: {
                top: { style: "thin", color: "black" },
                bottom: { style: "thin", color: "black" },
                left: { style: "thin", color: "black" },
                right: { style: "thick", color: "black" }
            }
        }
    ];
    if (tableName === "pageWiseImprovementItemBreakdown" || tableName === "improvementItemBreakdown") {
        const lastElement = specificAlignments[specificAlignments.length-1];
        specificAlignments[specificAlignments.length - 1] = { 
            ...lastElement, 
            col: 15 
        };
        endColumn = 15;
    }  
    // ETA Total calculation start
    const etaTableRange = XLSX.utils.decode_range(sheetName['!ref']);
    const etalastRow = etaTableRange.e.r;
    const etaTotalrow = etalastRow + 3;
    let totalsRowData;
    if (tableName === "improvementItemBreakdown") {
        totalsRowData = [{
            s_no: "",
            taskName: "Total",
            databaseAdministration: { f: `SUM(D${startRowNumber + 2}:D${etaTotalrow - 1})` },
            projectManagement: { f: `SUM(E${startRowNumber + 2}:E${etaTotalrow - 1})` },
            visualDesign: { f: `SUM(F${startRowNumber + 2}:F${etaTotalrow - 1})` },
            qa: { f: `SUM(G${startRowNumber + 2}:G${etaTotalrow - 1})` },
            api: { f: `SUM(H${startRowNumber + 2}:H${etaTotalrow - 1})` },
            research: { f: `SUM(I${startRowNumber + 2}:I${etaTotalrow - 1})` },
            programming: { f: `SUM(J${startRowNumber + 2}:J${etaTotalrow - 1})` },
            systemAdministration: { f: `SUM(K${startRowNumber + 2}:K${etaTotalrow - 1})` },
            "UI/Functionality": "",
            "Issue/Improvements": "",
            totalEfforts: { f: `SUM(N${startRowNumber + 2}:N${etaTotalrow - 1})` },
            remarks: ""
        }];
    } else {
        totalsRowData = [{
            s_no: "",
            taskName: "Total",
            databaseAdministration: { f: `SUM(D${startRowNumber + 2}:D${etaTotalrow - 1})` },
            projectManagement: { f: `SUM(E${startRowNumber + 2}:E${etaTotalrow - 1})` },
            visualDesign: { f: `SUM(F${startRowNumber + 2}:F${etaTotalrow - 1})` },
            qa: { f: `SUM(G${startRowNumber + 2}:G${etaTotalrow - 1})` },
            api: { f: `SUM(H${startRowNumber + 2}:H${etaTotalrow - 1})` },
            research: { f: `SUM(I${startRowNumber + 2}:I${etaTotalrow - 1})` },
            programming: { f: `SUM(J${startRowNumber + 2}:J${etaTotalrow - 1})` },
            systemAdministration: { f: `SUM(K${startRowNumber + 2}:K${etaTotalrow - 1})` },
            totalEfforts: { f: `SUM(L${startRowNumber + 2}:L${etaTotalrow - 1})` },
            remarks: ""
        }];
    }  
    XLSX.utils.sheet_add_json(sheetName, totalsRowData, { origin: `B${etaTotalrow}`, skipHeader: true });
    applyStyleToHeaderOrFooter(sheetName, etaTotalrow - 1, etaTotalrow - 1, 1, endColumn);
 
    applyStylesToSheet(sheetName, HeaderendRowNumber, contnetTableEndRow, 1, endColumn, specificAlignments);
}