import React from 'react';
import '../assets/css/dashboard.css'
import { dashboard_title_text } from '../service';

export default function Dashboard() {
  return (
    <div className='vins-pagespeed-info'>
      <section className='vins-info-content-image' >
        <div>
          <h1 className='vins-pagespeed-info-title'>{dashboard_title_text.vins_pagespeed_info_title}</h1>
          <div className="vins-audit-points">{dashboard_title_text.vins_audit_points}</div>
        </div>
      </section>
    </div>
  )
}
