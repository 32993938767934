import React from 'react';
import HeaderLogo from '../assets/images/header-logo.png';
import '../assets/css/header.css';
import { dashboard_title_text } from '../service';

export default function Header() {
  return (
    <div className='vins-app-header'>
      <header className='vins-page-header'>
        <div className='vins-logo-container'>
          <a className='vins-title' href='#'>
            <img className='vins-logo' src={HeaderLogo} alt="Header Logo" />
            <h1 className='vins-title'>{dashboard_title_text.vins_title}</h1>
          </a>
        </div>
      </header>
    </div>
  )
}
