import { improvementListHomePage, improvementListCollection, improvementListSearch, improvementListProduct, defaultImprovementValidationValue, themeDetails, page_dropdown } from "../service";
function getDetaisByScriptAndIdentifier(scriptElement, identifier) {
  const scriptContent = scriptElement.textContent;
  const indexOfThemeName = scriptContent.indexOf(identifier);
  let value = "error";
  if (indexOfThemeName !== -1) {
    const themeNameSubstring = scriptContent.substring(indexOfThemeName);
    const equalsIndex = themeNameSubstring.indexOf("=") + 1;
    let valueEndIndex = themeNameSubstring.indexOf(";", equalsIndex);
    if (valueEndIndex === -1) {
      valueEndIndex = themeNameSubstring.indexOf("\n", equalsIndex);
    }
    value = themeNameSubstring.substring(equalsIndex, valueEndIndex).trim().replace(/['"]/g, '');
  }
  return value;
}

const getImprovementData = (pageName) => {
  if (pageName == page_dropdown.home) return improvementListHomePage;
  if (pageName == page_dropdown.collection1 || pageName == page_dropdown.collection2) return improvementListCollection;
  if (pageName == page_dropdown.slp1 || pageName == page_dropdown.slp2) return improvementListSearch;
  if (pageName == page_dropdown.pdp1 || pageName == page_dropdown.pdp2) return improvementListProduct;
  return [];
}
const extractThemeDetails = (parsedResult) => {
  const scriptElement = parsedResult.querySelector('script.boomerang');
  if (!scriptElement) return "error";
  const themeName = getDetaisByScriptAndIdentifier(scriptElement, themeDetails.name_identifier);
  const themeVersion = getDetaisByScriptAndIdentifier(scriptElement, themeDetails.version_identifier);
  let description = "";
  if (themeName.includes("error") && !themeVersion.includes("error")) {
    description = themeDetails.description;
  } else {
    if (!themeName.includes("error")) {
      description += `Theme Name: ${themeName} \n`;
    }
    if (!themeVersion.includes("error")) {
      description += `Theme Version: ${themeVersion}`;
    }
  }
  return description;
}

const buildImprovementResult = (themeDetails, improvementData, parsedResult) => {
  const result = [];
  let serialNo = 1;
  let themeData = extractThemeDetails(parsedResult);
  if (themeData == "error") {
    themeData = themeDetails.description;
  }
  result.push({
    serialNo,
    pageName: themeDetails.page_name,
    sectionName: themeDetails.name,
    details: themeData,
    emptyField1: "", emptyField2: "", emptyField3: "", emptyField4: "", emptyField5: "", emptyField6: "",
    sectionResult: themeDetails.issue,
    issueType: themeDetails.issue_type,
    issueImpact: themeDetails.issue_impact,
    comments: themeDetails.comment,
    emptyField7: "",
    ui_hours: themeDetails.ui_hours,
    funtionality_hours: themeDetails.funtionality_hours
  });
  serialNo += defaultImprovementValidationValue.increement;
  improvementData.forEach(section => {
    const targetElement = parsedResult.querySelector(section.element_name)?.innerHTML.toLowerCase() || '';
    const hasIdentifier = section.identifier.some(identifier => targetElement.includes(identifier));
    const sectionResult = hasIdentifier ? defaultImprovementValidationValue.term_feature : defaultImprovementValidationValue.term_improvement;
    const description = hasIdentifier ? section.feature_description : section.improvement_description;
    result.push({
      serialNo,
      pageName: section.page_name,
      sectionName: section.name,
      details: description,
      emptyField1: "", emptyField2: "", emptyField3: "", emptyField4: "", emptyField5: "", emptyField6: "",
      sectionResult,
      issueType: section.issue_type,
      issueImpact: section.issue_impact,
      comments: section.comment,
      emptyField7: "",
      ui_hours: section.ui_hours,
      funtionality_hours: section.funtionality_hours
    });
    serialNo += defaultImprovementValidationValue.increement;
  });
  return result;
}

export const analyzeImprovement = async (url, pageName) => {
  const improvementData = getImprovementData(pageName);
  if (improvementData.length > 0) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ call: "get_site_content", url }),
      redirect: "follow"
    };
  
    try {
      const response = await fetch("https://shopifyauditwebsitecontentdev.vinsinfo.in/UI_improvement_functions.php", requestOptions);
      const result = await response.json();
      const parsedResult = new DOMParser().parseFromString(result, 'text/html');
      const improvementResult = buildImprovementResult(themeDetails, improvementData, parsedResult);
      return improvementResult;
    } catch (error) {
      console.error(error);
      return [];
    } 
  } else {
    return [
      {
        serialNo: "",
        pageName: "",
        sectionName: "",
        details: "",
        emptyField1: "", emptyField2: "", emptyField3: "", emptyField4: "", emptyField5: "", emptyField6: "",
        sectionResult: "",
        issueType: "",
        issueImpact: "",
        comments: "",
        emptyField7: "",
        ui_hours: "",
        funtionality_hours: ""
      }
    ]
  }
}

export const analyseImprovementItemBreakdown = async (improvementItems) => {
  const improvementItem = improvementItems.filter(item => item.sectionResult === "Improvement");
  const improvementItemBreakdown = []
  improvementItem.forEach(value => {
    const breakdown = {
      "S.No": improvementItemBreakdown.length + 1,
      "Task Name": value.pageName + " - " + value.sectionName,
      "Database Administration": '',
      "Project Management": '',
      "Visual Design": value.ui_hours,
      "QA": '',
      "API": '',
      "Research / Troubleshooting": '',
      "Programming": value.funtionality_hours,
      "System Administration": '',
      "UI/Functionality": value.issueType,
      "Issue/Improvements": value.sectionResult,
      "Total Efforts": '',
      "Remarks": ''
    }
    improvementItemBreakdown.push(breakdown);
  });
  return improvementItemBreakdown;
}

export const analysePageWiseImprovementItemBreakdown = async (improvementItemBreakdown) => {
  let pageWiseImprovementItemBreakdown = [];
  improvementItemBreakdown.forEach(breakdown => {
    let pageName = breakdown["Task Name"].split(" - ")[0];
    if (pageWiseImprovementItemBreakdown.some(item => item["Task Name"] === pageName)) {
      for (const item of pageWiseImprovementItemBreakdown) {
        if (item["Task Name"] === pageName) {
          item["Visual Design"] += breakdown["Visual Design"];
          item["Programming"] += breakdown["Programming"];
          break;
        }
      }
    } else {
      let updatedBreakdown = {
        ...breakdown,
        "S.No": pageWiseImprovementItemBreakdown.length + 1,
        "Task Name": pageName
      };
      pageWiseImprovementItemBreakdown.push(updatedBreakdown);
    }
  });
  
  return pageWiseImprovementItemBreakdown;
}