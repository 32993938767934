import React, { useCallback, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AnalyzeAllButton } from './analyzeall';
import { RemoveAllButton } from './removeall';
import { exportToExcel } from './exporttoexcel';
import { analyzePageSpeed } from './analysePageSpeedApi';
import loaderGif from '../assets/images/Loader.gif';
import { buttonText, dashboard_title_text, FormValues, page_dropdown, Specific_title_time, staticRecordsofImprovement, strategy, titleMapping, validationErrorMessage } from '../service';
import { analyseImprovementItemBreakdown, analysePageWiseImprovementItemBreakdown, analyzeImprovement } from './analyseImprovement';
import { useMediaQuery } from 'react-responsive';

export default function Formfields() {
    const [formFields, setFormFields] = useState([{ id: Date.now(), pageName: '', pageUrl: '', customPageName: '' }]);
    const [validationErrors, setValidationErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const addFormField = () => {
        setFormFields([...formFields, { id: Date.now(), pageName: '', pageUrl: '', customPageName: '' }]);
    };
    const removeFormField = (id) => {
        if (formFields.length > 1) {
            setFormFields(formFields.filter(field => field.id !== id));
        }
    };
    const removeAllFormFields = useCallback(() => {
        if (formFields.length > 1) {
            const lastField = formFields[formFields.length - 1];
            setFormFields([{ ...lastField, pageName: '', pageUrl: '', customPageName: '' }]);
        }
    }, [formFields]);
    const validateField = (field) => {
        const errors = {};
        if (!field.pageName) {
            errors[`${field.id}_pageName`] = validationErrorMessage.pageNameEmpty;
        }
        if (!field.customPageName && field.pageName == page_dropdown.other) {
            errors[`${field.id}_customPageName`] = validationErrorMessage.pageNameEmpty;
        }
        if (!field.pageUrl) {
            errors[`${field.id}_pageUrl`] = validationErrorMessage.pageUrlEmpty;
        } else if (!/^https?:\/\/.+\..+/.test(field.pageUrl)) {
            errors[`${field.id}_pageUrl`] = validationErrorMessage.validPageUrl;
        }
        return errors;
    };

    const validateFields = (analyzetype, field) => {
        const errors = {};

        if (analyzetype === 'audit_all') {
            formFields.forEach((formField) => {
                Object.assign(errors, validateField(formField));
            });
        } else if (field) {
            Object.assign(errors, validateField(field));
        }

        return errors;
    };
    const handleInputChange = (id, e) => {
        const { name, value } = e.target;
        setFormFields(formFields.map(field =>
            field.id === id ? { ...field, [name]: value } : field
        ));
        setValidationErrors(errors => ({
            ...errors,
            [`${id}_${name}`]: ''
        }));
    };
    const handleBlur = (id, e) => {
        const { name, value } = e.target;
        if (!value) {
            setValidationErrors(errors => ({
                ...errors,
                [`${id}_${name}`]: 'This field is required'
            }));
        }
    };
    const handleSelectChange = (id, e) => {
        handleInputChange(id, e);
    };

    const mergeAndRemoveDuplicates = (json1, json2) => {
        const combined = [...json1, ...json2];
        const mergedMap = new Map();
        combined.forEach(item => {
            if (!mergedMap.has(item.task)) {
                mergedMap.set(item.task, {
                    ...item,
                    urls: new Set(item.urls),
                    issueCount: 0
                });
            } else {
                const existing = mergedMap.get(item.task);
                item.urls.forEach(url => existing.urls.add(url));
            }
        });
        mergedMap.forEach(entry => {
            entry.issueCount = entry.urls.size;
            entry.urls = Array.from(entry.urls);
        });

        return Array.from(mergedMap.values());
    };
    function filterAndReorderData(data) {
        const uniqueData = data.reduce((acc, current) => {
            const exists = acc.find(item => item.task === current.task);
            if (!exists) {
                acc.push(current);
            }
            return acc;
        }, []);
        const otherItems = uniqueData.filter(item => item.page_name !== "Additional items");
        const additionalItems = uniqueData.filter(item => item.page_name === "Additional items");
        const staticRecords = staticRecordsofImprovement;
        const reorderedData = [...otherItems, ...staticRecords, ...additionalItems];
        return reorderedData.map((item, index) => ({
            ...item,
            s__No: index + 1
        }));
    }

    function roundToNearestHalf(value) {
        if (value <= 0.5 && value > 0) {
            return 0.5;
        } else {
            return Math.floor(value * 2) / 2;
        }
    };

    function calculateProgrammingTimeAndUi(title, issueCount, defaultProgramming, defaultUi) {
        const specialTitles = Specific_title_time;
        let programmingTime = roundToNearestHalf(defaultProgramming * issueCount);
        let uiTime = roundToNearestHalf(defaultUi * issueCount);
        if (specialTitles[title]) {
            const titleConfig = specialTitles[title];
            if (issueCount < 20) programmingTime = titleConfig.less_than20_programming;
            else if (issueCount < 40) programmingTime = titleConfig.less_than40_programming;
            else programmingTime = titleConfig.greater_than40_programming;
            if (titleConfig.less_than20_ui && issueCount < 20) uiTime = titleConfig.less_than20_ui;
            else if (titleConfig.less_than40_ui && issueCount < 40) uiTime = titleConfig.less_than40_ui;
            else if (titleConfig.greater_than40_ui) uiTime = titleConfig.greater_than40_ui;
        }

        return { programmingTime, uiTime };
    }
    function mapDescriptions(titleJsonData, ImprovementsheetsData) {
        const mappedResults = [];
        let serialNo = 1;
        titleJsonData.forEach((item) => {
            const matchingEntry = Object.values(ImprovementsheetsData).find(
                (improvement) => improvement.task === item.task
            );
            if (matchingEntry) {
                const issueCounts = item.issueCount !== undefined ? item.issueCount : 1;
                const { programmingTime, uiTime } = calculateProgrammingTimeAndUi(item.task, issueCounts, matchingEntry.programming, matchingEntry.design);
                const mappedEntry = {
                    serialNo,
                    task: matchingEntry.task,
                    db_administration: matchingEntry.database,
                    project_management: matchingEntry.project,
                    visual_design: uiTime,
                    qa: matchingEntry.qa,
                    api: matchingEntry.api,
                    research: matchingEntry.research,
                    programming_time: programmingTime,
                    system_administration: matchingEntry.system,
                    total_effort: matchingEntry.total_efforts,
                    remarks_eta: matchingEntry.remarks
                };
                serialNo++;
                mappedResults.push(mappedEntry);
            }
        });
        return mappedResults;
    }
    function getImprovementData(desktopData, mobileData) {
        if (desktopData.score < 60 && mobileData.score <= 100) {
            return mergeAndRemoveDuplicates(desktopData.messages, mobileData.messages);
        }
        return [];
    }
    const analyseFormURL = async (id) => {
        const field = getFieldById(id);
        if (!field) return;

        const errors = validateFields("audit", field);
        setValidationErrors(errors);
        if (Object.keys(errors).length > 0) return;

        setLoading(true);
        const { desktopData, mobileData, improvementItem, improvementItemforBreaktime } = await getPageData(field);
        let PageSpeedImprovementData = getImprovementData(desktopData, mobileData);
        const {
            improvementItemBreakdown,
            pageWiseImprovementItemBreakdown,
            PageScoreData
        } = await getImprovementBreakdownData(improvementItemforBreaktime, field, desktopData, mobileData);
        PageSpeedImprovementData = filterImprovementData(PageSpeedImprovementData);
        const EtaDataDetails = mapDescriptions(PageSpeedImprovementData, titleMapping);
        if (hasErrors(desktopData, mobileData)) {
            handleError();
        } else {
            exportDataAndNotify(
                PageScoreData,
                PageSpeedImprovementData,
                EtaDataDetails,
                improvementItem,
                improvementItemBreakdown,
                pageWiseImprovementItemBreakdown
            );
        }
        setLoading(false);
    };
    const getFieldById = (id) => formFields.find(field => field.id === id);
    const getPageData = async (field) => {
        const desktopData = await analyzePageSpeed(field.pageUrl, strategy.desktop);
        const mobileData = await analyzePageSpeed(field.pageUrl, strategy.mobile);
        const improvementItemforBreaktime = await analyzeImprovement(field.pageUrl, field.pageName);
        const improvementItem = improvementItemforBreaktime.map(({ ui_hours, funtionality_hours, ...rest }) => rest);

        return { desktopData, mobileData, improvementItem, improvementItemforBreaktime };
    };
    const getImprovementBreakdownData = async (improvementItem, field, desktopData, mobileData) => {
        const improvementItemBreakdown = await analyseImprovementItemBreakdown(improvementItem);
        const pageWiseBreakdownData = improvementItemBreakdown.map(({ "UI/Functionality": _, "Issue/Improvements": __, ...rest }) => rest);
        const pageWiseImprovementItemBreakdown = await analysePageWiseImprovementItemBreakdown(pageWiseBreakdownData);
        const PageScoreData = [
            {
                serialNo: 1,
                pageName: field.pageName === page_dropdown.other ? field.customPageName : field.pageName,
                pageUrl: field.pageUrl,
                desktop_score: desktopData.score,
                desktop_fcp: desktopData.fcp,
                desktop_lcp: desktopData.lcp,
                desktop_cls: desktopData.cls,
                mobile_score: mobileData.score,
                mobile_fcp: mobileData.fcp,
                mobile_lcp: mobileData.lcp,
                mobile_cls: mobileData.cls
            }
        ];

        return { improvementItemBreakdown, pageWiseImprovementItemBreakdown, PageScoreData };
    };
    const filterImprovementData = (PageSpeedImprovementData) => {
        return PageSpeedImprovementData.length === 0 ? [] : filterAndReorderData(PageSpeedImprovementData);
    };
    const hasErrors = (desktopData, mobileData) => {
        return desktopData.score === "Error" || mobileData.score === "Error";
    };
    const handleError = () => {
        toast.error('Please try again later', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: 'vins-custom-toast-error',
        });
    };
    const exportDataAndNotify = (PageScoreData, PageSpeedImprovementData, EtaDataDetails, improvementItem, improvementItemBreakdown, pageWiseImprovementItemBreakdown) => {
        exportToExcel(PageScoreData, PageSpeedImprovementData, EtaDataDetails, improvementItem, improvementItemBreakdown, pageWiseImprovementItemBreakdown, 'Feature-list.xlsx');
        toast.success('Audit completed successfully!', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: 'vins-custom-toast-success',
        });
    };
    const analyzeAllURLs = useCallback(async () => {
        const errors = validateFields("audit_all");
        setValidationErrors(errors);
        if (Object.keys(errors).length > 0) return;
        setLoading(true);
        let { allResults, allImprovements, UiImprovements } = await analyzeFormFields(formFields);
        allImprovements = processImprovements(allImprovements);
        const uniqueUiImprovement = filterUniqueUiImprovements(UiImprovements);
        for (let itr = 0; itr < uniqueUiImprovement.length; itr++) {
            uniqueUiImprovement[itr].serialNo = itr + 1;
        }
        const improvementItemBreakdown = await analyseImprovementItemBreakdown(UiImprovements);
        const pageWiseBreakdownData = improvementItemBreakdown.map(({
            "UI/Functionality": _,
            "Issue/Improvements": __,
            ...rest
        }) => rest);
        const pageWiseImprovementItemBreakdown = await analysePageWiseImprovementItemBreakdown(pageWiseBreakdownData);
        const EtaDataDetails = mapDescriptions(allImprovements, titleMapping);
        const hasError = allResults.some(record =>
            record.desktop_score === "Error" || record.mobile_score === "Error"
        );

        if (hasError) {
            toast.error('Please try again later', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'vins-custom-toast-error',
            });
            setLoading(false);
        } else {
            exportToExcel(allResults, allImprovements, EtaDataDetails, uniqueUiImprovement, improvementItemBreakdown, pageWiseImprovementItemBreakdown, 'Feature-list.xlsx');
            setLoading(false);
            toast.success('Audit completed successfully!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'vins-custom-toast-success',
            });
        }
    }, [formFields, validateFields, mergeAndRemoveDuplicates, mapDescriptions, exportToExcel]);
    async function analyzeFormFields(formFields) {
        const allResults = [];
        const allImprovements = [];
        const UiImprovements = [];
        let S_No = 1;
        let SerialNo = 1;
        for (const field of formFields) {
            if (field.pageUrl) {
                const { desktopData, mobileData, improvementItem } = await analyzePage(field);
                const PageSpeedImprovementData = getImprovementData(desktopData, mobileData);
                UiImprovements.push(...updateUiImprovements(improvementItem, UiImprovements));
                allResults.push(createResultObject(SerialNo++, field, desktopData, mobileData));
                processPageSpeedImprovements(PageSpeedImprovementData, allImprovements, S_No);
                S_No += PageSpeedImprovementData.length;
            }
        }
        return { allResults, allImprovements, UiImprovements };
    }

    async function analyzePage(field) {
        const improvementItem = await analyzeImprovement(field.pageUrl, field.pageName);
        const desktopData = await analyzePageSpeed(field.pageUrl, strategy.desktop);
        const mobileData = await analyzePageSpeed(field.pageUrl, strategy.mobile);
        return { desktopData, mobileData, improvementItem };
    }
    function updateUiImprovements(improvementItem, UiImprovements) {
        return improvementItem.map(element => {
            element.serialNo = UiImprovements.length + 1;
            return element;
        });
    }
    function createResultObject(serialNo, field, desktopData, mobileData) {
        return {
            serialNo,
            pageName: field.pageName === page_dropdown.other ? field.customPageName : field.pageName,
            pageUrl: field.pageUrl,
            desktop_score: desktopData.score,
            desktop_fcp: desktopData.fcp,
            desktop_lcp: desktopData.lcp,
            desktop_cls: desktopData.cls,
            mobile_score: mobileData.score,
            mobile_fcp: mobileData.fcp,
            mobile_lcp: mobileData.lcp,
            mobile_cls: mobileData.cls
        };
    }
    function processPageSpeedImprovements(PageSpeedImprovementData, allImprovements, S_No) {
        PageSpeedImprovementData.forEach(improvement => {
            const uniqueUrls = [...new Set(improvement.urls)];
            const issueCount = uniqueUrls.length;
            const existingImprovement = allImprovements.find(existing => existing.task === improvement.task);
            if (existingImprovement) {
                existingImprovement.urls = [...new Set([...existingImprovement.urls, ...uniqueUrls])];
                existingImprovement.issueCount = existingImprovement.urls.length;
            } else {
                allImprovements.push({
                    s__No: S_No,
                    page_name: improvement.page_name,
                    modules: improvement.modules,
                    improvement_item: improvement.improvement_item,
                    task: improvement.task,
                    empty: '',
                    description: improvement.description,
                    remarks: improvement.remarks,
                    issueCount: issueCount,
                    urls: uniqueUrls
                });
            }
        });
    }
    function processImprovements(allImprovements) {
        if (allImprovements.length === 0) return allImprovements;
        return filterAndReorderData(allImprovements);
    }
    function filterUniqueUiImprovements(UiImprovements) {
        const seen = new Set();
        return UiImprovements.filter(item => {
            const identifier = `${item.sectionName}-${item.pageName}`;
            if (seen.has(identifier)) {
                return false;
            } else {
                seen.add(identifier);
                return true;
            }
        }).map(({ ui_hours, funtionality_hours, ...rest }) => rest);
    }
    return (
        <div className='vins-form-fields-container'>
            <div className='vins-form-header'>
                <div className='vins-field-titles'>
                    <div className='vins-page-title'>{dashboard_title_text.vins_page_title}</div>
                    <div className='vins-page-url'>{dashboard_title_text.vins_page_url}</div></div>
                <div className='vins-add-pages-btn'>
                    <button
                        className='vins-add-button'
                        onClick={addFormField}
                    >
                        <span className='vins-plus-icon'>+</span> {buttonText.Btn_add}
                    </button>
                </div>
            </div>
            <div className='vins-all-form-group'>
                {formFields.map((field) => {
                    const pageNameInvalid = validationErrors[`${field.id}_pageName`] ? 'invalid' : '';
                    const pageNameFieldClass = `vins-page-name-field ${pageNameInvalid}`;
                    const customPageNameInvalid = validationErrors[`${field.id}_customPageName`] ? 'invalid' : '';
                    const customPageNameclass = `vins-page-name-field ${customPageNameInvalid}`;
                    const pageUrlInvalid = validationErrors[`${field.id}_pageUrl`] ? 'invalid' : '';
                    const pageUrlFieldClass = `vins-url-field ${pageUrlInvalid}`;
                    return (
                        <div key={field.id} className='vins-form-group'>
                            <div className="vins-page-dropdown-containter">
                                {field.pageName === 'Others' ? (
                                    <input
                                        id={`pageName-${field.id}`}
                                        type="text"
                                        name="customPageName"
                                        className={customPageNameclass}
                                        value={field.customPageName}
                                        onChange={(e) => handleInputChange(field.id, e)}
                                        onBlur={(e) => handleBlur(field.id, e)}
                                        placeholder={FormValues.CustomPageNamePlaceholder}
                                        required
                                    />
                                ) : (
                                    <>
                                        <select
                                            id={`pageName-${field.id}`}
                                            name="pageName"
                                            className={pageNameFieldClass}
                                            value={field.pageName}
                                            onChange={(e) => handleSelectChange(field.id, e)}
                                            onBlur={(e) => handleBlur(field.id, e)}
                                            required
                                        >
                                            <option value="" className='vins-default-option-name' disabled>
                                                {FormValues.PageNamePlaceholder}
                                            </option>
                                            <option value={page_dropdown.home}>{page_dropdown.home}</option>
                                            <option value={page_dropdown.collection1}>{page_dropdown.collection1}</option>
                                            <option value={page_dropdown.collection2}>{page_dropdown.collection2}</option>
                                            <option value={page_dropdown.pdp1}>{page_dropdown.pdp1}</option>
                                            <option value={page_dropdown.pdp2}>{page_dropdown.pdp2}</option>
                                            <option value={page_dropdown.slp1}>{page_dropdown.slp1}</option>
                                            <option value={page_dropdown.slp2}>{page_dropdown.slp2}</option>
                                            <option value={page_dropdown.other}>{page_dropdown.other}</option>
                                        </select>
                                        <svg className="vins-dropdown-icon" viewBox="0 0 10 6"><path fill="currentColor" fillRule="evenodd" d="M9.354.646a.5.5 0 0 0-.708 0L5 4.293 1.354.646a.5.5 0 0 0-.708.708l4 4a.5.5 0 0 0 .708 0l4-4a.5.5 0 0 0 0-.708" clipRule="evenodd"></path></svg>
                                    </>
                                )}
                            </div>
                            <div className='vins-url-input-audit-remove-sets'>
                                <input
                                    id={`pageUrl-${field.id}`}
                                    name='pageUrl'
                                    className={pageUrlFieldClass}
                                    type='url'
                                    placeholder={isMobile ? FormValues.PageUrlMobilePlaceholder : FormValues.PageUrlDesktopPlaceholder}
                                    value={field.pageUrl}
                                    onChange={(e) => handleInputChange(field.id, e)}
                                    onBlur={(e) => handleBlur(field.id, e)}
                                    required
                                />
                                <div className='vins-audit-remove-btns'>
                                    <span className="vins-vertical-line"></span>
                                    <span
                                        className="vins-analyze-button"
                                        onClick={() => analyseFormURL(field.id)}
                                        role="button"
                                    >
                                        {buttonText.Btn_audit}
                                    </span>
                                    <span className="vins-vertical-line"></span>
                                    <span
                                        className='vins-remove-button'
                                        onClick={() => removeFormField(field.id)}
                                        role="button"
                                    >
                                        {buttonText.Btn_remove}
                                    </span>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className='vins-buttons-container'>
                <AnalyzeAllButton analyzeAll={analyzeAllURLs} formfieldCount={formFields.length} />
                <RemoveAllButton removeAll={removeAllFormFields} formfieldCount={formFields.length} />
            </div>
            {loading && <div className="vins-loader">
                <div className='vins-loader-container'>
                    <div className="vins-loading-text">Exporting audit information in (.xlsx) format.</div>
                    <img src={loaderGif} alt="Loading..." />
                </div>
            </div>}
            <ToastContainer />
        </div>

    );
}