// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --title-txt-color: #111111;
    --header-border-color: #dadce0;
    --btn-border-color: #000;
}
a { text-decoration: none; }
.vins-page-header { padding: 10px 0px; border-bottom: 1px solid var(--header-border-color); }
.vins-title, .vins-page-btns { display: flex; align-items: center;gap: 20px; }
.vins-logo { width: 100%; max-width: 55px; }
.vins-title { color: var(--title-txt-color); font-size: 18px;font-family: "Inter-medium", sans-serif; }
.vins-btn { padding: 10px; border: 1px solid var(--btn-border-color); border-radius: 5px; font-size: 14px; }`, "",{"version":3,"sources":["webpack://./src/assets/css/header.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,8BAA8B;IAC9B,wBAAwB;AAC5B;AACA,IAAI,qBAAqB,EAAE;AAC3B,oBAAoB,iBAAiB,EAAE,mDAAmD,EAAE;AAC5F,+BAA+B,aAAa,EAAE,mBAAmB,CAAC,SAAS,EAAE;AAC7E,aAAa,WAAW,EAAE,eAAe,EAAE;AAC3C,cAAc,6BAA6B,EAAE,eAAe,CAAC,uCAAuC,EAAE;AACtG,YAAY,aAAa,EAAE,yCAAyC,EAAE,kBAAkB,EAAE,eAAe,EAAE","sourcesContent":[":root {\r\n    --title-txt-color: #111111;\r\n    --header-border-color: #dadce0;\r\n    --btn-border-color: #000;\r\n}\r\na { text-decoration: none; }\r\n.vins-page-header { padding: 10px 0px; border-bottom: 1px solid var(--header-border-color); }\r\n.vins-title, .vins-page-btns { display: flex; align-items: center;gap: 20px; }\r\n.vins-logo { width: 100%; max-width: 55px; }\r\n.vins-title { color: var(--title-txt-color); font-size: 18px;font-family: \"Inter-medium\", sans-serif; }\r\n.vins-btn { padding: 10px; border: 1px solid var(--btn-border-color); border-radius: 5px; font-size: 14px; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
